import React from 'react';
import { shape, number, string, arrayOf } from 'prop-types';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';

const SingleTagLayout = styled(Layout)`
  em {
    text-transform: capitalize;
  }

  ul {
    list-style-type: none;
    margin: 2rem 0;

    li {
      a {
        font-size: 2rem;
        padding: 1rem 2rem;
        display: block;

        &:hover {
          color: #e0e0e0;
        }
      }
    }
  }
`;

const Tags = ({
  pageContext: { tag },
  data: {
    allMarkdownRemark: { edges, totalCount },
  },
}) => {
  return (
    <SingleTagLayout>
      <h1>
        {totalCount} post{totalCount === 1 ? '' : 's'} tagged with <em>&quot;{tag}&quot;</em>
      </h1>
      <ul>
        {edges.map(({ node: { fields: { slug }, frontmatter: { title } } }) => {
          return (
            <li key={slug}>
              <Link to={slug} partiallyActive={true}>
                {title}
              </Link>
            </li>
          );
        })}
      </ul>
      <Link to="/tags">View list of all tags and associated articles</Link>
    </SingleTagLayout>
  );
};

Tags.propTypes = {
  pageContext: shape({
    tag: string.isRequired,
  }),
  data: shape({
    allMarkdownRemark: shape({
      totalCount: number.isRequired,
      edges: arrayOf(
        shape({
          node: shape({
            frontmatter: shape({
              title: string.isRequired,
            }),
            fields: shape({
              slug: string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, draft: { ne: true } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
